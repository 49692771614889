.chessboard {
  display: flex;
  flex-wrap: wrap;
  width: 525px;
  height: 525px;
  border: 2px solid black;
  margin-top: 20px;
}
.hiddenBoard { 
  width: 525px;
  height: 525px;
  border: 2px solid black;
  margin-top: 20px;
}
.chessboard-container { 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sideBySide {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 720px) {

  .sideBySide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
  }

  .chessboard-container {
    flex-direction: row;
    justify-content: center;
  }

}

.bottom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100px;
}

.button {
  background-color: #f2f2f2;
  border: none;
  color: #333;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 30px;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  height: fit-content;
  min-width: 100px;
}

.button:hover {
  background-color: #ddd;
}

.button:focus {
  outline: none;
}
