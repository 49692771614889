.square {
    
  }
  
  .white {
    background-color: white;
  }
  
  .black {
    background-color: gray;
  }
  
  .white-square-wrong {
    animation: white-red-pulse 0.2s;
  }
  
  .black-square-wrong {
    animation: black-red-pulse 0.2s;
  }

  .white-to-green {
    animation: white-green-pulse 0.2s;
  }

  .black-to-green {
    animation: black-green-pulse 0.2s;
  }

  .green-square-clicked {
    animation: white-green-pulse 0.5s;
  }
  
  @keyframes white-green-pulse {
    0% {
      background-color: rgb(255, 254, 254);
    }
    50% {
      background-color:  rgb(179, 194, 170);
    }
    100% {
      background-color: #77dd77;
    }
  }

  @keyframes black-green-pulse {
    0% {
      background-color: grey;
    }
    50% {
      background-color:  rgb(179, 194, 170);
    }
    100% {
      background-color: #77dd77;
    }
  }
  
  @keyframes black-red-pulse {
    0% {
      background-color: grey;
    }
    50% {
      background-color:  rgb(255, 94, 94);
      
    }
    100% {
      background-color: grey;
    }
  }
  
  @keyframes white-red-pulse {
    0% {
      background-color: rgb(255, 255, 255);
    }
    50% {
      background-color:  rgb(255, 94, 94);
    }
    100% {
      background-color: rgb(255, 255, 255);
    }
  }