.history-container {
  width: 150px;
  height: 460px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 30px;
}

.timer-container {
  height: auto;
}

.options-container {
    margin-top: 10px;
    height: 100px; 
    width: auto;
}

.overall-container {
  margin-left: 20px;
  margin-right: 20px;
}

.history-container h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.move {
  font-size: 1rem;
  margin-bottom: 10px;
  margin-top: 10px;
}

.invalidMove {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
  margin-top: 10px;
  color: rgb(125, 88, 199);
}

.move p:last-of-type {
  margin-bottom: 0;
}

.move p:first-of-type {
  margin-top: 10;
}

@media only screen and (max-width: 757px) {
  .history-container {
    width: 527px;
    height: 70px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 0px;
  }
  .overall-container {
    width: 530px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .move {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .invalidMove {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
    color: red;
    font-style: italic;
  }
}
